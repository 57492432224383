#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 230px;
  background-color: #fff;
  border-right: 1px solid #ccc;
  padding: 20px;
  overflow-y: auto;
}

.btn-side {
  background-color: #ce4815;
}

.button {
  list-style-type: none;
  padding-left: 0;
}

.menu-list {
  list-style-type: none;
  padding-left: 0;
}

.menu-list li {
  position: relative;
}

.menu-list li a {
  color: #333; /* Default text color */
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Hover effect */
.menu-list li a:hover {
  color: #f1821e; /* Hover text color */
  background-color: #f9f9f9; /* Optional hover background for a subtle effect */
  border-radius: 5px; /* Adds rounded corners on hover */
  padding: 5px; /* Adds spacing for hover state */
}

/* Active link style */
.menu-list li a.active {
  color: #ff6600; /* Active text color */
  font-weight: bold; /* Makes active links more prominent */
}

.menu-label {
  margin-top: 10px;
  font-weight: bold;
  color: #4d4b4b;
}

/*navbar*/
.navbar-user {
  padding: 8px 16px;
  background-color: #fff;
}

.navbar-user-img {
  max-width: 250px;
}

.navbar-user .navbar-toggler {
  background-color: #a0a2a5; 
  border: none; 
}

.navbar-user .navbar-toggler:hover,
.navbar-user .navbar-toggler:focus {
  background-color: #4d4b4b;
  outline: none; 
}
.navbar-user-icon {
  color: rgb(0, 0, 0);
}

.navbar-user .nav-link {
  color: #6c757d; 
  text-decoration: none;
}

.navbar-user .nav-link:hover {
  color: #f1821e; 
  text-decoration: none;
}
.navbar-user .nav-link.active {
  color: #ff6600; 
}

.navbar-user .dropdown-item {
  color: #6c757d;
}
.navbar-user .dropdown-title {
  color: #6c757d; /* Atur warna teks untuk dropdown title */
}
.navbar-user .dropdown-item:hover {
  color: #f1821e; 
  background-color: transparent;
}