.navbar-profile {
  top: 0;
  right: 0;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.profile-image .user {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 1px solid #ccc;
}

.profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #007bff;
  color: white;
}

.dropdown-item {
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.username {
  margin-left: 10px;
  color: white;
}

.welcome {
  margin-bottom: 0px;
}

.table {
  font-size: 14px;
}

.button-tabel {
  font-size: 12px;
  color: #fff;
}

.button-detail {
  font-size: 14px;
  color: #fff;
}

.poin {
  margin-bottom: 0;
}

.subpoin p {
  margin-bottom: 0;
}

.add-lahan {
  display: flex;
  justify-content: space-between;
}

.add-lahan .column {
  width: 48%;
}

.add-lahan .column .field .input {
  width: 80%;
}
.field .control .input {
  width: 40%;
}

.field .control .textarea {
  width: 40%;
}

.add-logistik {
  display: flex;
  justify-content: space-between;
}

.add-logistik .column {
  width: 48%;
}

.add-logistik .column .field .input {
  width: 80%;
}

.add-logistik .column .field .textarea {
  width: 80%;
}

.user-details-container {
  display: flex;
  align-items: flex-start;
}

.user-additional-info {
  max-width: 50%;
  margin-right: 10px;
}

.user-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  background: #a0a0a0;
}

.card-welcome {
  box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
}
.card-detail-harga {
  background: linear-gradient(50deg, #ce4815 18.44%, #f0c016 108.28%);
  color: #ffff;
  border-radius: 8px;
}

.chart-container {
  background-color: #ffffff; 
  border-radius: 8px; 
  box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18); 
  margin: 0 auto;
}

.grade-card {
  background: linear-gradient(50deg, #ce4815 18.44%, #f0c016 108.28%);
  color: white;
  padding: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px; 
  box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 7px 10px 7px 0px rgba(0, 0, 0, 0.18); 
}


.vertical-divider {
  width: 1px;
  height: 120px;
  background-color: white;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease;
}

.tab.active {
  color: #ff4500;
  font-weight: bold;
}

.tab:hover {
  color: #ff7043;
}

.tab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #ff4500;
  transition: width 0.3s ease;
}

.tab:hover::after,
.tab.active::after {
  width: 100%;
}

.content-tanam {
  margin-top: 20px;
}
