body {
  font-family: "Poppins", sans-serif;
  background: #f3f3f3;
  scroll-behavior: smooth;
}

.header-well {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 27px 81.3px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 90px;
  flex-shrink: 0;
  font-size: 35px;
  padding-left: 40px;
  margin-top: 15px;
}

@media only screen and (max-width: 360px) {
  /* Mengatur lebar div well */
  .header-well {
    width: 100%;
    padding: 5px;
  }

  /* Mengatur lebar gambar */
  img {
    width: 25px; /* Atur sesuai kebutuhan */
    height: 30px; /* Atur sesuai kebutuhan */
  }

  /* Mengatur padding untuk teks */
  strong {
    padding-left: 10px; /* Atur sesuai kebutuhan */
    font-size: 30px;
  }
}

strong {
  background: linear-gradient(100deg, #ce4815 18.44%, #f0c016 108.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 27px 81.3px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.form {
  text-align: center; /* Optional: agar teks berada di tengah secara horizontal */
}

.form button {
  background-color: #1a4bc9;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#result-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.result {
  width: 100%; /* Sesuaikan lebar */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
}
.table-container {
  max-height: 300px; /* Tentukan tinggi maksimum */
  overflow-y: auto; /* Scrollbar vertikal jika konten melebihi tinggi */
  overflow-x: hidden; /* Sembunyikan overflow horizontal */
  width: 100%; /* Lebar penuh dari parent container */
  box-sizing: border-box; /* Menghindari overflow karena padding atau border */
  margin-top: 10px; /* Jarak antara form dan tabel */
  border: 1px solid #ccc; /* Batasan tabel */
  border-radius: 15px; /* Menambahkan sudut bulat */
  background-color: #fff;
}

.table-scroll {
  overflow-x: auto;
  overflow-y: auto;
}

.back {
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.alert {
  background-color: #f8d7da; /* Warna latar belakang merah muda */
  color: #721c24; /* Warna teks merah gelap */
  border-radius: 5px; /* Sudut melengkung */
  margin-top: 15px; /* Jarak atas */
  padding: 10px; /* Padding di dalam elemen */
  font-size: 14px; /* Ukuran font */
  text-align: center; /* Teks rata tengah */
  word-wrap: break-word; /* Memecah kata jika terlalu panjang */
  white-space: normal; /* Mengizinkan teks untuk pindah ke baris baru */
  width: 100%;  /* Pastikan alert tidak melebihi lebar form */
  box-sizing: border-box;  /* Menghitung padding dan border dalam lebar elemen */
}
